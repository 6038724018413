import Button from 'components/common/button/Button';
import Loader from 'components/common/loader/Loader';

import formatDate from 'utils/formatDate';
import formatNumber from 'utils/formatNumber';

function totalAddressesFormater(cell, row) {
    return formatNumber(cell);
}

function dateFormatter(cell, row) {
    return formatDate.dateAndTime(cell);
}

function actionsFormatter(cell, row) {
    if(row.deletingAddresses && row.showDeleteConfForRow === row.importLogId) {
        return (
            <Loader 
                loadingMessage='Deleting addresses...'
            />
        );
    }

    if(row.showDeleteConfForRow && row.showDeleteConfForRow === row.importLogId) {
        return (
            <div className='d-flex column-gap-1 align-items-center flex-wrap'>
                <div>Delete this file and all its addresses?</div>
                <div className='d-flex column-gap-1 row-gap-1 flex-wrap'>
                    <Button 
                        btnType='outline-primary'
                        label='Yes'
                        btnStyling={{width: '55px', height: 'fit-content', padding: '0.25rem'}}
                        action={() => row.onConfirmDelete(row.importLogId)}
                    />
                    <Button
                        btnType='outline-danger'
                        label='Cancel'
                        btnStyling={{width: '55px', height: 'fit-content', padding: '0.25rem'}}
                        action={() => row.onCancelDelete()}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className='d-flex column-gap-1'>
            <i 
                className='fas fa-trash-alt cursor-pointer c-danger'
                onClick={() => row.onDelete(row.importLogId)}
            ></i>
            <i 
                className='fas fa-file-download cursor-pointer brand-primary-color'
                onClick={() => row.onDownload(row.filename)}
            >
            </i>
        </div>
    );
}

const importsColumns = [
    {
        title: '',
        dataIndex: 'importLogId',        
        filterName: 'importLogId',
        key: 'importLogId',
        dataField: 'importLogId',
        hidden: true,
        csvExport: false,
    },
    {
        title: 'Import Date',
        label: 'Import Date',
        dataIndex: 'createdDate',        
        filterName: 'createdDate',
        key: 'createdDate',
        dataField: 'createdDate',
        render: dateFormatter
    },
    {
        title: 'Saved Addresses',
        label: 'Saved Addresses',
        dataIndex: 'totalAddresses',        
        filterName: 'totalAddresses',
        key: 'totalAddresses',
        dataField: 'totalAddresses',
        render: totalAddressesFormater
    },
    {
        title: 'File Name',
        label: 'File Name',
        dataIndex: 'filename',        
        filterName: 'filename',
        key: 'filename',
        dataField: 'filename',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',        
        filterName: 'actions',
        key: 'actions',
        dataField: 'totaactionslAddresses',
        render: actionsFormatter
    }
];

export default importsColumns;