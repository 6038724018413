import Switch from 'components/common/switch/Switch';
import LicenseSelection from '../LicenseSelection';

function dmExemptFormatter(cell, row) {
    if(!row) return null;
    const franchiseId = row?.franchiseId;
    let isDmExempt = row?.isDmExempt;
    let label = isDmExempt ? "Yes" :"No";

        return (
            <div id='dashboard-table-row-actions'>
                <div className='flex-grow-1'>
                    <Switch
                        id={`dm-exempt-${franchiseId}`}
                        label= {label}
                        labelTop={false}
                        checked={isDmExempt}
                        onToggle={() => row.onChangeDmExemptStatus(franchiseId,isDmExempt)} 
                        />
                </div>
            </div>
        );

    return (
        '-'
    );
}
function qrCodeFormatter(cell, row) {
    if(!row) return null;
    const franchiseId = row?.franchiseId;
    let isQrCode = row?.isQrCode;
    let label = isQrCode ? "Yes" :"No";
    return (
        <div id='dashboard-table-row-actions'>
            <div className='flex-grow-1'>
                <Switch
                    id={`dm-qr-${franchiseId}`}
                    label= {label}
                    labelTop={false}
                    checked={isQrCode}
                    onToggle={() => row.onChangeQrCode(franchiseId,isQrCode)} 
                    />
            </div>
        </div>
    );
}

function licenseFormatter(cell, row) {
    if(!row) return null;
    const franchiseId = row?.franchiseId;
    const licenseNumber =row?.licenseNumber;
    const overrideLicense =row?.overrideLicense;

        return (
            <div id='dashboard-table-row-actions'>
                <div className='flex-grow-1'>
                    
                <LicenseSelection
                        licenseNumber={licenseNumber}
                        franchiseId={franchiseId}
                        overrideLicense={overrideLicense}
                        onSaveLicense={row.onChangeLicense }
                        saving={row.savingFranchise}
                    />
                    <br></br>
                   
                </div>
            </div>
        );

}
function FranchiseNameFormatter(cell, row) {
    if(!row) return null;
    const franchiseName = row?.franchiseName ? row?.franchiseName :'-';
    return (
        franchiseName
    );
}

const importsColumns = [
    {
        title: '',
        dataIndex: 'franchiseId',
        hidden: true,
        csvExport: false,
        filterName: 'franchiseId',
        key: 'franchiseId',
        dataField: 'franchiseId',
    },
    {
        title: 'External Id',
        dataIndex: 'franchiseExternalId',                
        filterName: 'franchiseExternalId',
        key: 'franchiseExternalId',
        dataField: 'franchiseExternalId',
    },
    {        
        title: 'Franchise Name',
        dataIndex: 'franchiseName',                
        filterName: 'franchiseName',
        key: 'franchiseName',
        dataField: 'franchiseName',        
        render: FranchiseNameFormatter
    },
    {
        title: 'Franchise City',
        dataIndex: 'franchiseCity',                
        filterName: 'franchiseCity',
        key:  'franchiseCity',
        dataField: 'franchiseCity',        
    },
    {
        title: 'DM Exempt',
        dataIndex: 'isDmExempt',                
        filterName: 'isDmExempt',
        key: 'isDmExempt',
        dataField: 'isDmExempt',        
        render: dmExemptFormatter
    },
    {
        title: 'Do you want a QR code?',
        dataIndex: 'isQrCode',                
        filterName: 'isQrCode',
        key: 'isQrCode',
        dataField: 'isQrCode',        
        render: qrCodeFormatter
    },
    {
        title: 'License',
        dataIndex: 'license',                
        filterName: 'license',
        key: 'license',
        dataField: 'license',        
        render: licenseFormatter
    }
];

export default importsColumns;