import { Link } from 'react-router-dom';

import Button from 'components/common/button/Button';

import formatCurrency from 'utils/formatCurrency';
import validateStatus from 'utils/validateStatus';

function idFormatter(cell, row) {
    if (!row.id) {
        return (
            <Button
                btnType='outline-primary'
                label='Create Plan'
                action={() => row.onCreateNewPlan(row)}
                btnStyling={{
                    height: 'auto',
                    width: 'auto',
                    padding: '0.5rem 0.75rem',
                }}
            />
        );
    }

    const location = {
        pathname: `/lmpbp/dashboard/${row.id}`,
        state: {
            budgetPlan: row.budgetPlan,
            templateYear: row.templateYear,
            templateId: row.templateId,
            owners: row.owners,
            franchiseName: row.franchise,
        },
    };

    return (
        <Link
            id='lmpbp-dashboard-table-row-budget-plan-id'
            to={location}
            className='budget-plan-link'
        >
            {cell || '-'}
        </Link>
    );
}

function totalFormatter(cell) {
    return `$${formatCurrency(cell)}`;
}

function statusFormatter(cell) {
    if (validateStatus.isSubmitted(cell)) {
        return <span className='status submitted'>Submitted</span>;
    } else if (validateStatus.isApproved(cell)) {
        return <span className='status approved'>Approved</span>;
    } else if (validateStatus.isRejected(cell)) {
        return <span className='status rejected'>Rejected</span>;
    } else if (validateStatus.isUnsubmitted(cell)) {
        return <span className='status not-submitted'>Not Submitted</span>;
    } else if (validateStatus.isDraft(cell)) {
        return <span className='status draft'>In Progress</span>;
    }

    return <span className='status draft'>-</span>;
}

export const columns = [
    {
        title: '',
        label: '',
        dataIndex: 'key',
        filterName: 'key',
        key: 'key',
        dataField: 'key',
        csvExport: false,
        hidden: true    
    },
    {
        title: 'Plan ID',
        label: 'Plan ID',
        dataIndex: 'id',
        filterName: 'id',
        key: 'id',
        dataField: 'id',
        sorter: true,
        render: idFormatter
    },
    {
        title: 'Franchise',
        label: 'Franchise',
        dataIndex: 'franchise',
        filterName: 'franchise',
        key: 'franchise',
        dataField: 'franchise',
        sorter: true,
    },
    {
        title: 'Owners',
        label: 'Owners',
        dataIndex: 'owners',
        filterName: 'owners',
        key: 'owners',
        dataField: 'owners',
        sorter: true,
    },
    {
        title: 'Status',
        label: 'Status',
        dataIndex: 'status',
        filterName: 'status',
        key: 'status',
        dataField: 'status',
        sorter: true,
        render: statusFormatter,
    },
    {
        title: 'Total',
        label: 'Total',
        dataIndex: 'total',
        filterName: 'total',
        key: 'total',
        dataField: 'total',
        sorter: true,
        render: totalFormatter,
    },
];
