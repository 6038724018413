import formatCurrency from 'utils/formatCurrency';
import formatDate from 'utils/formatDate';
import formatNumber from 'utils/formatNumber';
import userSession from 'utils/userSession';

function moneyFormatter(cell) {
    if(isNaN(cell)) return cell;

    return `$${formatCurrency(Number(cell))}`;
}

function cellFormatter(cell) {
    return <span 
        style={{ overflowWrap: 'break-word' }}
    >
        {cell || '-'}
    </span>;
}

let campaignsReportColumns = [
    {
        title: 'Franchise ID',
        dataIndex: 'franchiseId',        
        filterName: 'franchiseId',
        key: 'franchiseId',
        dataField: 'franchiseId',        
        render: cellFormatter
    },
    {
        title: 'Franchise Name',
        dataIndex: 'franchiseName',        
        filterName: 'franchiseName',
        key: 'franchiseName',
        dataField: 'franchiseName',        
        render: cellFormatter
    },
    {
        title: 'Return Address',
        dataIndex: 'returnAddress',        
        filterName: 'returnAddress',
        key: 'returnAddress',
        dataField: 'returnAddress',        
        render: cellFormatter
    },
    {
        title: 'NetSuite ID',
        dataIndex: 'netSuiteId',        
        filterName: 'netSuiteId',
        key: 'netSuiteId',
        dataField: 'netSuiteId',        
        render: cellFormatter
    },
    {
        title: 'Marketing Phone',
        dataIndex: 'marketingPhone',        
        filterName: 'marketingPhone',
        key: 'marketingPhone',
        dataField: 'marketingPhone',        
        render: cellFormatter
    },
    {
        title: userSession.isMTUser()?'Location URL' :'Marketing URL',
        dataIndex: 'marketingUrl',        
        filterName: 'marketingUrl',
        key: 'marketingUrl',
        dataField: 'marketingUrl',        
        render: cellFormatter
    },
    {
        title: 'Brochure Code',
        dataIndex: 'brochureCode',        
        filterName: 'brochureCode',
        key: 'brochureCode',
        dataField: 'brochureCode',        
        render: cellFormatter
    },
    {
        title: 'Coupons',
        dataIndex: 'coupons',        
        filterName: 'coupons',
        key: 'coupons',
        dataField: 'coupons',        
        render: cellFormatter
    },
    {
        title: 'Coupons Expiration Date',
        dataIndex: 'couponsExpirationDate',        
        filterName: 'couponsExpirationDate',
        key: 'couponsExpirationDate',
        dataField: 'couponsExpirationDate',        
        formatter: (cell) => formatDate.date(cell) || '-'
    },
    {
        title: 'Services',
        dataIndex: 'services',        
        filterName: 'services',
        key: 'services',
        dataField: 'services',
        formatter: cellFormatter
    },
    {
        title: 'Badges',
        dataIndex: 'badges',        
        filterName: 'badges',
        key: 'badges',
        dataField: 'badges',
        formatter: cellFormatter
    },
    {
        title: 'Min. Addresses Required',
        dataIndex: 'minAddressesRequired',        
        filterName: 'minAddressesRequired',
        key: 'minAddressesRequired',
        dataField: 'minAddressesRequired',
        formatter: (cell) => cellFormatter(formatNumber(cell))
    },
    {
        title: 'Min. Dollar Spend',
        dataIndex: 'minSpendAmount',        
        filterName: 'minSpendAmount',
        key: 'minSpendAmount',
        dataField: 'minSpendAmount',
        formatter: (cell) => moneyFormatter(cell) || '-'
    },
    {
        title: 'Expiration Date',
        dataIndex: 'expiration_date_msq',        
        filterName: 'expiration_date_msq',
        key: 'expiration_date_msq',
        dataField: 'expiration_date_msq',
        formatter: (cell) => formatDate.date(cell) || '-'
    },
    {
        title: 'Desired In Home Date',
        dataIndex: 'desired_in_home_date',        
        filterName: 'desired_in_home_date',
        key: 'desired_in_home_date',
        dataField: 'desired_in_home_date',
        formatter: (cell) => formatDate.date(cell) || '-'
    },
    {
        title: 'Intended Spend',
        dataIndex: 'budget_intention',        
        filterName: 'budget_intention',
        key: 'budget_intention',
        dataField: 'budget_intention',
        formatter: (cell) => cell || '-'
    },
    {
        title: 'Piece Size',
        dataIndex: 'piece_size',        
        filterName: 'piece_size',
        key: 'piece_size',
        dataField: 'piece_size',
        formatter: (cell) => cell || '-'
    }
];

export default campaignsReportColumns;