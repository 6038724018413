import formatCurrency from 'utils/formatCurrency';
import formatNumber from 'utils/formatNumber';

function investmentFormatter(cell, row) {
    if(isNaN(cell)) return cell;

    return `$${formatCurrency(Number(cell))}`;
}

function addressesFormatter(cell, row) {
    if(!cell) return '-';

    return formatNumber(cell);
}

const campaignColumns = [
    {
        title: '',
        dataIndex: 'id',                
        filterName: 'id',
        key: 'id',
        dataField: 'id',
        hidden: true
    },
    {
        title: 'Campaign Date',
        dataIndex: 'date',                
        filterName: 'date',
        key: 'date',
        dataField: 'date',
    },
    {
        title: 'Services',
        dataIndex: 'services',                
        filterName: 'services',
        key: 'services',
        dataField: 'services',
    },
    {
        title: 'Brochure',
        dataIndex: 'brochure',                
        filterName: 'brochure',
        key: 'brochure',
        dataField: 'brochure',
    },
    {
        title: 'Coupons',
        dataIndex: 'coupons',                
        filterName: 'coupons',
        key: 'coupons',
        dataField: 'coupons',
    },
    {
        title: 'Badges',
        dataIndex: 'badges',                
        filterName: 'badges',
        key: 'badges',
        dataField: 'badges',
    },
    {
        title: 'Investment',
        dataIndex: 'investment',                
        filterName: 'investment',
        key: 'investment',
        dataField: 'investment',
        render: investmentFormatter
    },
    {
        title: 'Addresses',
        dataIndex: 'addresses',                
        filterName: 'addresses',
        key: 'addresses',
        dataField: 'addresses',
        render: addressesFormatter
    },
    {
        title: 'Expiration Date',
        dataIndex: 'expiration_date',                
        filterName: 'expiration_date',
        key: 'expiration_date',
        dataField: 'expiration_date',
    },
    {
        title: 'Desired In Home Date',
        dataIndex: 'desired_in_home_date',                
        filterName: 'desired_in_home_date',
        key: 'desired_in_home_date',
        dataField: 'desired_in_home_date',
    },
    {
        title: 'Intended Spend',
        dataIndex: 'budget_intention',                
        filterName: 'budget_intention',
        key: 'budget_intention',
        dataField: 'budget_intention',
    },
    {
        title: 'Piece Size',
        dataIndex: 'piece_size',                
        filterName: 'piece_size',
        key: 'piece_size',
        dataField: 'piece_size',
    }
];

export default campaignColumns;